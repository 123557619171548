<template>
  <div>
    <myHeader :status="2"></myHeader>
    <div
      class="school-contain"
      :class="{ windowsAppV2: $store.state.isWindowsAppV2 }"
    >
      <div class="title">我的网校</div>
      <ul class="list">
        <li
          class="item"
          v-for="item in jgList"
          :key="item.uid"
          @click="switchSchool(item)"
          :class="{ selected: item.select }"
          :title="item.title"
        >
          <template v-if="item.jg_id > 0">
            <div class="name">{{ item.title }}</div>
            <div class="role">
              <span class="icon"></span>
              <span class="text">{{ item.role.join(',') }}</span>
            </div>
            <div class="ext-time">
              {{ item.version_name }}
              <template v-if="item.vip_expiry > 0">
                | 有效期：{{ item.vip_expiry | formatTimeStamp('yyyy-MM-dd') }}
              </template>
            </div>
          </template>
          <template v-else>
            <div class="create-new-jg">点击去创建网校</div>
          </template>
        </li>
        <li v-if="jgList.length % 3 > 0" class="item empty"></li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import bg from '@/mixin/background'
import myHeader from '@/components/Header'

export default {
  name: 'switchSchool',

  components: {
    myHeader,
  },

  mixins: [bg],

  data() {
    return {
      jgList: [],
    }
  },

  computed: {
    ...mapState(['userInfo']),
  },

  created() {
    this.getJgList()
  },

  methods: {
    getJgList() {
      this.$http({
        url: '/user/jiGouList',
        callback: ({ data }) => {
          this.jgList = data
        },
      })
    },

    switchSchool({ jg_id }) {
      this.$http({
        url: '/wxCustom/switchUser',
        data: { jg_id },
        callback: () => {
          // 本地运行的情况下 去设置session
          // if (process.env.NODE_ENV !== 'production') {
          //   this.$store.dispatch('getUserInfo').then(() => {
          //     this.$router.replace({
          //       path: this.$store.getters.homePath,
          //     })
          //   })
          // } else {
          //   location.href = this.$store.getters.homePath
          // }
          this.$store.dispatch('getUserInfo').then(() => {
            this.$router.replace({
              path: this.$store.getters.homePath,
            })
          })
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.school-contain {
  width: 980px;
  padding: 30px;
  min-height: 388px;
  margin: 33px auto;
  background: #fff;
  &.windowsAppV2 {
    width: 100%;
    height: auto;
    min-height: auto;
    max-width: 860px;
    box-sizing: border-box;
    .item {
      width: 250px !important;
    }
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 20px;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      width: 286px;
      height: 133px;
      padding: 20px;
      cursor: pointer;
      margin-bottom: 20px;
      box-sizing: border-box;
      position: relative;
      border: 1px solid #f3f3f3;
      box-shadow: 0px 3px 22px 0px rgba(0, 0, 0, 0.04);
      .create-new-jg {
        font-size: 16px;
        color: #333;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-weight: 600;
      }
      &.empty {
        border: 0;
        cursor: auto;
        box-shadow: none;
        &:hover {
          background: none;
        }
      }
      &.selected:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-left: 4px solid #0aa29b;
      }
      &:hover {
        background: #fffefb;
      }
      .name {
        font-size: 16px;
        font-weight: bold;
        color: #4a4a4a;
      }
      .role {
        margin-top: 10px;
        display: flex;
        align-items: center;
        .icon {
          width: 12px;
          height: 12px;
          background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAVpJREFUKFOVUrFKA0EQnbccnk2KTcp8gIUgCqLpbINgeVhYJFjsHSLYx+Ya09vIZQtJCgu5UghprQwiqIWFH3BlsmAaT8KObLgLaJcpdofHvJk3bxdURBAEa1JKBeCEmTcdDOCDme+MMTpN058F5o4oiurM/MDM60R0LYR4dri1do+ILgB8AzhKkiSD61ytVsfM/GmMaVUqFd/zvK4jzOfzzmw2y6WUAwAb0+m0AaXUORGdGWO23VilVIeIrgqll1rrbiH3jYhuEIbhEzP3tdY9V6SUahFRvyC0tdaDAg8BtN2ELyHEQZIkr6UBYRgeurzX6w1LLIqiHWvt4x9CHMdelmVNAA1XyMzjer0+iuN4viSUknzf7+d57tzZKrsW97vv+/t5nrdLSYuliWiXmVMACzllMPMQQEBEL4ul/9l6WqvVjq21TUcQQowmk8m9lPJ2aevKD7fq1/gFcA3JkwWBbzIAAAAASUVORK5CYII=');
          background-size: 100%;
          min-width: 12px;
        }
        .text {
          flex: 1;
          font-size: 13px;
          color: #666666;
          margin-left: 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .ext-time {
        font-size: 13px;
        color: #999999;
        margin-top: 33px;
      }
    }
  }
}
</style>
